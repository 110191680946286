import React from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../views/Post.js"


export default function PostPage({ data, pageContext }) {
  const post = data.allWpPost.nodes[0]
  const seoImage = post.featuredImage.node.mediaItemUrl
//   const { postCategory,postLink } = pageContext
  const title = post.title
  const excerpt = post.excerpt
  const postLink = post.slug
  const baseUrl = 'https://whealthventures.com/resources/';
  
  return (   
    <Layout background={"#fff"}>
      <SEO title={title}
          image={seoImage}
          url={baseUrl+postLink}
          description={excerpt.replace(/(<([^>]+)>)/gi, "")}
          />
      <Post data={post} title={title} featuredImage={seoImage}></Post>
    </Layout> 
  )
}
export const query = graphql`
  query($slug: String) {
    allWpPost(filter: { slug: { eq: $slug } }) {
        nodes {
          title
          id
          content
          date(formatString: "Do MMMM YYYY")
          slug
          excerpt
          categories {
            nodes {
              slug
              name
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          author {
            node {
              name
              slug
            }
          }
          blogFields {
            readingTime
          }
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
    }
  }
`