import React from 'react';
import styled from 'styled-components'
// import PostShare from "../components/PostShare"
import { Link } from "gatsby"
import ShareButtons from "../components/ShareButtons"
import LogoBlock from "../components/LogoBlock"
const PostContentBlock = styled.div`
    margin-left:auto;
    margin-right:auto;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #333;
    width:80%;
    padding:60px 0 60px 0;
    overflow:hidden;
    & blockquote{
        width: 80% ;
        margin: auto;
        border-left: 4px solid #d1a760;
    }
    & blockquote p{
        width: 90%;
    }
    & figcaption{
        opacity: 0.8;
        font-family: Roboto;
        font-size: 14px;
        margin-top:20px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.39;
        letter-spacing: normal;
        text-align: center;
        color: #2e3439;
    }
    & table{
        width: 80%;
        margin: auto;
    }
    & iframe{
        margin:20px auto 20px auto;
        width:80%;
    }
    & .wp-block-columns{
        display: flex;
        width:100%;
    }
    & .wp-block-column{
        display: flex;
        flex-direction:column;
        margin: auto;
    }

    & h1{
        font-size: 2.5em;
        font-weight: bold;
        line-height: 1.2;        
        color: #000;
        z-index: 99;  
        width:100%;  
        margin:auto;
        margin-bottom:0.5em;
    }
    & h2{
        font-size: 30px;
        font-weight: bold;        
        line-height: 1.2;
        width:100%;  
        margin:auto;
        margin-bottom:1em;
        color:#000;
    }
    & h4{
        font-size: 20px;
        font-weight: 700;        
        line-height: 1.2;
        width:100%;  
        margin:auto;
        margin-bottom:1em;
        color:#333;
    }
    & h1 strong{
        font-weight:700;
    }
    & h2 strong{
        font-weight:700;
    }
    & h3 strong{
        font-weight:700;
    }
    & h4 strong{
        font-weight:700;
    }
    & p strong{
        font-weight:500;
    }
    & ul{
        width:90%;  
        margin:auto;
        padding-left:10px;
        margin-bottom:1.45em;
    }
    & .wp-block-image{
        width:auto;  
        margin:3% 0 3% 0;
    }
    & figure{
        margin-top:2em;
        display: flex;
        flex-direction:column;
    }
    & figure.alignright span{
        margin-left:auto;
        margin-right:0px;
    }
    & figure img{
        object-fit:contain;
    }
    
    & ol{
        width: 80%;
        margin: auto;
        padding-top: 1%;
        padding-bottom: 20px;
    }
    & ol li{
        margin-left:5%;
    }
    & ol li p{
        width:100%;
        margin:auto;
    }
    & p{
        width:100%;  
        margin-left:auto;
        margin-right:auto;
    }
    & .twitter-tweet{
        margin-left: 10%;
    }
    & .instagram-media{
        margin-left:10% !important;
    }
    & .post-title{
        width:80%;
        font-size:3em;
        color:#000;
        text-align:left;
        line-height:1.23;
        margin-bottom:0.5em;
    }
    & .wp-block-cover{
        margin-bottom:3.5em;
        margin:auto; 
        background-size:cover;
        background-position:50% 50%;
    }
    & .gatsby-image-wrapper{
        margin:auto;
    }
    & .wp-block-column img{
        object-fit: contain !important;
    }
    
    @media screen and (max-width: 991px) {
        font-size:16px;
        & figcaption{
            font-size:16px;
        }
        & .post-title{
            width:90%;
        }
        & h1{
            font-size: 2.6em;
        }
        & table{
            overflow:auto;
        }
        & .wp-block-columns{
           flex-direction:column;
        }
    }
    @media screen and (max-width: 767px) {}
    @media screen and (max-width: 479px) {
        padding:40px 0 40px 0;
        & h1{            
            width:100% !important;  
        }
        & h2{
            width:100%;  
        }
        & ul{
            width:100%;  
        }
        & .wp-block-image{ 
            margin:3% 0 3% 0;
        }
        & figure{
            margin-top:2em;
        }
        & ol{
            width:100%;  
            margin:auto;
            padding-top:1%;
        }
        & ol li{
            margin-left:5%;
        }
        & ol li p{
            width:100%;
        }
        & p{
            width:100%;  
        }
        
        & .post-title{
            width:100%;
            margin-bottom:0.5em;
        }       
        & img{
            margin-left:0%;
        }
        & .twitter-tweet{
            margin-left: 0%;
        }
        & .instagram-media{
            margin-left:0% !important;
        }
        & table{
            width: 100%;
            margin: auto;
        }
        & iframe{
            width: 100%;
        }
        & .wp-block-column{
            width:100%;
        }
    }
`;
const BannerSection = styled.div`
    display:flex;
    flex-direction:row-reverse;
    width:100%;
    height:468px;
    position:relative;
    @media screen and (max-width: 991px) {
        height:768px;
    }   
    @media screen and (max-width: 479px) {
        flex-direction:column;
        height:auto;
    }   
    `;
const BannerContentWrap = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:flex-start;
    width:50%;
    padding:0 2.5% 0 2.5%;;
    height:100%;
    background:#eaf3f9;
    @media screen and (max-width: 991px) {
        justify-content:center;
    }
    @media screen and (max-width: 767px) {
        padding:50px 2.5vw 70px 2.5vw;
        width:100%;
    }
    @media screen and (max-width: 479px) {
      margin-bottom:00px;
      padding:50px 4% 50px 4%;
    }
`;
const BannerImageContainer = styled.div`
    width:50%;
    position:relative;
    height:100%;  
    background:#eaf3f9; 
    z-index:0;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    @media screen and (max-width: 767px) {
        height:60vh;
        width:100%;
    }
    @media screen and (max-width: 479px) {
        height:50vh;
    }
`;
const BannerImage = styled.img`
    object-fit:cover;
    object-position:0% 50%;
    width:100%;
    height:100%;
`;
// const LogoContainer = styled.div`
//     position:absolute;
//     top:0%;
// `;
const LogoContainer = styled.div`
    width:100%;
    display:flex;
    position:absolute;
    top:0%;
    flex-direction:column;
    justify-content:flex-start;
    @media screen and (max-width: 479px) {
        width:50%;
    }
`;
const PostTitle = styled.h1`
    font-family: Montserrat;
    font-size: 35px;
    font-weight: 200;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    margin-top:25px;
    letter-spacing: normal;
    text-align: left;
    color: #2e3439;
    @media screen and (max-width: 991px) {
        margin-top:50px;
    }
    @media screen and (max-width: 479px) {
        font-size: 30px;
        margin-top:0px;
    }
`;
const AuthorContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    margin-right:0;
    margin-left:auto;
`;
const AuthorName = styled.div`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: right;
    color: #3c57a1;
    text-transform: capitalize;
`;
const Endline = styled.div`
    width:20%;
    height:2px;
    background:#3c57a1;
    margin:0px auto 0 auto;
`;
const PostInfo = styled(AuthorName)`
    color: #2e3439;
    font-size:16px;
    font-weight:300;
    text-transform: none;
`;
const Post = (props) => {
    const title = props.data.title;
    const tags = props.data.tags.nodes.name;
    const excerpt = props.data.excerpt;
    const url = "https://whealthventures.com/resources/" + props.data.slug;
    const twitterHandle = "whealthventures";
    return (
        <div>
            <BannerSection>
                <BannerImageContainer>
                    <BannerImage src={props.data.featuredImage.node.mediaItemUrl}></BannerImage>
                </BannerImageContainer>
                <BannerContentWrap>
                    <LogoContainer>
                        <LogoBlock width={"100%"}></LogoBlock>
                    </LogoContainer>
                    <PostTitle>{props.data.title}</PostTitle>
                    <AuthorContainer>
                        <AuthorName>{props.data.author.node.name}</AuthorName>
                        <PostInfo>{props.data.date}</PostInfo>
                    </AuthorContainer>
                </BannerContentWrap>
            </BannerSection>
            <PostContentBlock>
                <div dangerouslySetInnerHTML={{ __html: props.data.content }} style={{ "width": "100%" }}></div>
            </PostContentBlock>
            <Endline></Endline>
            <ShareButtons title={title} url={url} twitterHandle={twitterHandle} tags={tags} excerpt={excerpt}></ShareButtons>
            {/* <PostShare></PostShare>             */}

            {/* <Title size="xl" weight="bold" bottom="2rem" >read more like this...</Title>      */}
            {/* <RecoSlider postCategory={props.postCategory} title={props.title}></RecoSlider> */}
        </div>
    );
};

export default Post;