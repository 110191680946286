import React from 'react'
import styled from "styled-components"
import {
    EmailIcon,
EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share'

const SharePostWrap=styled.div`
    display:flex;
    flex-direction:row;
    margin-top: 20px;
    margin-left: auto;
    margin-right:15%;
    justify-content:flex-end;
    align-items:center;  
    margin-bottom:30px;  
    @media screen and (max-width: 479px) {
        flex-direction:column;
        justify-content:flex-start;
        align-items:flex-start
        margin-left:auto;
        margin-right:auto;
        width:80%;
    }
`;
const ShareText=styled.div`
    margin-right: 0px;
    font-family: Roboto, sans-serif;
    color: #333;
    font-weight: 300;
    @media screen and (max-width: 479px) {
        margin-left:0px;
        margin-right:auto;
    }
`;
const ShareIcons=styled.div`
    display:flex;
    justify-content:flex-end;
    align-items:center;
    @media screen and (max-width: 479px) {
        margin-left:0px;
        margin-top:20px;
        margin-right:auto;
    }
`;
const ShareButtons = ({title, url, twitterHandle, tags,excerpt}) => {

    return(
        <SharePostWrap>
            <ShareText>Share:</ShareText>
            <ShareIcons>
                <FacebookShareButton url={url} >
                    <FacebookIcon  size={45} iconFillColor={"#3c57a1"} />
                </FacebookShareButton>
                <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
                        <TwitterIcon  size={45} iconFillColor={"#3c57a1"} />
                </TwitterShareButton>
                <LinkedinShareButton url={url} title={title} summary={excerpt} source={"https://whealthventures.com/"} >
                    <LinkedinIcon  size={45} iconFillColor={"#3c57a1"}/>
                </LinkedinShareButton>
                <WhatsappShareButton url={url} title={title}>
                    <WhatsappIcon  size={35} iconFillColor={"#3c57a1"}/>
                </WhatsappShareButton>
                <EmailShareButton url={url} subject={title} body={url}>
                    <EmailIcon  size={55} iconFillColor={"#3c57a1"}/>
                </EmailShareButton>
            </ShareIcons>          
        </SharePostWrap>
      )

}
export default ShareButtons