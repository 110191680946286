import React from 'react';
import styled from "styled-components"
import {Link} from "gatsby"
import Logo from "../images/w-health-logo.png"

const LogoLink = styled(Link)`
    text-decoration:none;
    outline:none;
`;
const LogoContainer=styled.div`
    width:${props => props.width?props.width:'50%'};
    height:auto;
     z-index:0;
`;
const LogoImg=styled.img`
    width:240px;
    height:auto;
    margin:30px 0 0px 0;
    @media screen and (max-width: 991px) {
      margin:30px 0 0px 0;
  }
  @media screen and (max-width: 479px) {
    margin:20px 0 0px 0;
}
`;

const LogoBlock = (props) => {
    return (
        <LogoContainer width={props.width}>
            <LogoLink to="/"><LogoImg src={Logo}></LogoImg></LogoLink>
        </LogoContainer>
    );
};

export default LogoBlock;